import React, { useState } from 'react'
import Header from '../components/header'
import Edit from './Edit'
import { Grid, Box, Button } from '@chakra-ui/core'
import { useGlobalState, setGlobalState } from '../store'
import { setRemoteCall } from '../remote'
import TableProvider from '../components/Table/Table'

/**
 * Table collumns
 *
 */
export const collumns = [
  { k: '_id', l: '_id' },
  { k: 'country', l: 'country', required: true },
  { k: 'region', l: 'region', required: true },
  { k: 'name', l: 'name', required: true },
  { k: 'member', l: 'Member', required: true },
  { k: 'iata', l: 'iata', required: false },
  { k: 'icao', l: 'icao', required: false },
  { k: 'airport', l: 'airport', required: false },
  { k: 'summer', l: 'summer', required: false },
  { k: 'winter', l: 'winter', required: false },
  // { k: 'restrictions', l: 'restrictions', required: false },
  // { k: 'rules', l: 'rules', required: false },
  // { k: 'spc', l: 'spc', required: false },
  // { k: 'pax', l: 'pax', required: false },
  // { k: 'mvt', l: 'mvt', required: false },
  { k: 'visibility', l: 'visibility', required: false },
  { k: 'coordenationLink', l: 'coordenationLink', required: false },
  { k: 'attr', l: 'Atributes' },
  { k: 'lastUpdate', l: 'Last Updated', t: 'datetime' },
  { k: 'author', l: 'Author' }
]

export default function Airports(props) {
  /**
   * Get Data
   */
  const [openModal, setOpenModal] = useState(false)
  const [airports, setAirports] = useGlobalState('airports')
  const [members, setMembers] = useGlobalState('members')
  const [authors, setAuthors] = useGlobalState('users')
  //set the initical class name
  setGlobalState('pageClass', 'airports')

  /**
   * Table options
   */
  const options = {
    filter: true,
    filterType: 'dropdown',
    count: airports ? airports.length : 0,
    downloadOptions: { filename: 'report.csv', separator: ';' },
    tableBodyHeight: 'calc( 100vh - 260px )',

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(
        sel => airports[sel.dataIndex]?._id
      )

      const actionMessage = ` Deleted Airport`

      return (
        <div className="table-actions">
          <Button
            variantColor="red"
            onClick={() =>
              setRemoteCall('delAirports', selectedIds, actionMessage)
            }
          >
            Delete Selected
          </Button>
        </div>
      )
    },
    onRowClick: (rowData, rowMeta) => {
      setOpenModal(airports[rowMeta.dataIndex])
    }
  }

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall('setAirports', data, notice)
  }

  return (
    <div className={'table-wrapper'}>
      <Box w="100%" className={'content'} padding={'2em'}>
        <Header>
          {/* <button onClick={() => props.socket.emit('page', 'games')}>
          Isto é o que está a acontecer hoje
        </button> */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box w="100%">
              <h1>Airports</h1>
            </Box>

            <Box w="100%" h="10">
              <Button
                active={props.periodType === 'week'}
                width={'49%'}
                marginLeft={'49%'}
                variantColor="green"
                onClick={() => setOpenModal({})}
              >
                <span className={`text`}>
                  <span className={`icon-plus icons`} /> {'Add'}
                </span>
              </Button>
            </Box>
          </Grid>
        </Header>
        {airports && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <TableProvider
                data={airports}
                columns={collumns.map(key => {
                  if (key.k === 'status') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          switch (Number(value)) {
                            case 0:
                              return 'Disabled'
                            case 1:
                              return 'Active'
                            case 2:
                              return 'Alocated'
                            default:
                              break
                          }
                        }
                      }
                    }
                  }
                  if (key.k === 'member') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (members && value) {
                            const thisAuthor = members.find(t =>
                              value.includes(t._id)
                            )
                            return thisAuthor?.name || thisAuthor?.country
                          }
                          return ''
                        }
                      }
                    }
                  }
                  if (key.k === 'author') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (authors && value) {
                            const thisAuthor = authors.find(t =>
                              value.includes(t._id)
                            )
                            return thisAuthor && thisAuthor.domain
                          }
                          return ''
                        }
                      }
                    }
                  }
                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== '_id' &&
                        key.k !== '__v' &&
                        key.k !== 'content' &&
                        key.k !== 'attr' &&
                        key.k !== 'startDate' &&
                        key.k !== 'seo'
                    }
                  }
                })}
                options={options}
              />
            </div>
          </div>
        )}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns}
            set={set}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
    </div>
  )
}
