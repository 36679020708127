import React, { useState } from 'react'
import Header from '../components/header'
import Edit from './Edit'
import { Grid, Box, Button } from '@chakra-ui/core'
import { useGlobalState, setGlobalState } from '../store'
import { setRemoteCall } from '../remote'
import TableProvider from '../components/Table/Table'

/**
 * Table collumns
 *
 */
export const collumns = [
  { k: '_id', l: '_id' },
  { k: 'country', l: 'country', required: true },
  { k: 'region', l: 'region', required: true },
  { k: 'website', l: 'website', required: false },
  { k: 'name', l: 'name', required: false },
  { k: 'coorWebsite', l: 'coorWebsite', required: false },
  { k: 'email', l: 'email', required: false },
  { k: 'contact', l: 'contact', required: false },
  { k: 'contactTitle', l: 'contactTitle', required: false },
  { k: 'agencyName', l: 'agencyName', required: false },
  // { k: 'phone', l: 'phone', required: false },
  { k: 'fax', l: 'fax', required: false },
  { k: 'sita', l: 'sita', required: false },
  { k: 'requestEmail', l: 'requestEmail' },
  // { k: 'address', l: 'address' },
  // { k: 'computerSystem', l: 'computerSystem' },
  { k: 'image', l: 'image' },
  { k: 'organisation', l: 'organisation' },
  { k: 'fundings', l: 'fundings' },
  { k: 'status', l: 'status' },
  { k: 'attr', l: 'Atributes' },
  { k: 'lastUpdate', l: 'Last Updated', t: 'datetime' },
  { k: 'author', l: 'Author' }
]

export default function Members(props) {
  /**
   * Get Data
   */
  const [openModal, setOpenModal] = useState(false)
  const [members, setMembers] = useGlobalState('members')
  const [authors, setAuthors] = useGlobalState('users')
  //set the initical class name
  setGlobalState('pageClass', 'members')

  /**
   * Table options
   */
  const options = {
    filter: true,
    filterType: 'dropdown',
    count: members ? members.length : 0,
    downloadOptions: { filename: 'report.csv', separator: ';' },
    tableBodyHeight: 'calc( 100vh - 260px )',

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(
        sel => members[sel.dataIndex]?._id
      )

      const actionMessage = ` Deleted Airport`

      return (
        <div className="table-actions">
          <Button
            variantColor="red"
            onClick={() =>
              setRemoteCall('delMembers', selectedIds, actionMessage)
            }
          >
            Delete Selected
          </Button>
        </div>
      )
    },
    onRowClick: (rowData, rowMeta) => {
      setOpenModal(members[rowMeta.dataIndex])
    }
  }

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall('setMembers', data, notice)
  }

  return (
    <div className={'table-wrapper'}>
      <Box w="100%" className={'content'} padding={'2em'}>
        <Header>
          {/* <button onClick={() => props.socket.emit('page', 'games')}>
          Isto é o que está a acontecer hoje
        </button> */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box w="100%">
              <h1>Members</h1>
            </Box>

            <Box w="100%" h="10">
              <Button
                active={props.periodType === 'week'}
                width={'49%'}
                marginLeft={'49%'}
                variantColor="green"
                onClick={() => setOpenModal({})}
              >
                <span className={`text`}>
                  <span className={`icon-plus icons`} /> {'Add'}
                </span>
              </Button>
            </Box>
          </Grid>
        </Header>
        {members && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <TableProvider
                data={members}
                columns={collumns.map(key => {
                  if (key.k === 'status') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if(!value) return 'Active'
                          switch (Number(value)) {
                            case 0:
                              return 'Active'
                            case 1:
                              return 'Pending'
                            case 2:
                              return 'Disabled'
                            default:
                              break
                          }
                        }
                      }
                    }
                  }

                  if (key.k === 'author') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (authors && value) {
                            const thisAuthor = authors.find(t =>
                              value.includes(t._id)
                            )
                            return thisAuthor && thisAuthor.domain
                          }
                          return ''
                        }
                      }
                    }
                  }
                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== '_id' &&
                        key.k !== '__v' &&
                        key.k !== 'content' &&
                        key.k !== 'attr' &&
                        key.k !== 'startDate' &&
                        key.k !== 'seo'
                    }
                  }
                })}
                options={options}
              />
            </div>
          </div>
        )}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns}
            set={set}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
    </div>
  )
}
