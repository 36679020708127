import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState, setStorageState } from '../../store'

import {
  ListContainer,
  // ItemNumb,
  StyledDrawer,
  StyledListItem,
  StyledActiveListItem,
  StyledNavigationPanel,
  MenuIcon,
  StyledLogo,
  StyledListWrap,
  StyledList
} from './styled'

const MenuItems = props => <StyledListItem></StyledListItem>

const Navigation = props => {
  const [show, setShow] = useState(false)
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn')
  const handleToggle = () => setShow(!show)

  const logout = () => {
    setStorageState('loggedIn', null)
    setLoggedIn(null)
  }

  return (
    <StyledNavigationPanel>
      <StyledDrawer variant="permanent" anchor="left">
        <ListContainer>
          <StyledLogo onClick={() => handleToggle()}>CMS</StyledLogo>
          {/* {show && ( */}
          <StyledListWrap>
            <StyledList>
              <StyledListItem>
                <Link to={'/dashboard'} onClick={() => handleToggle()}>
                  <MenuIcon className={`icon-wrench icons`} />
                  <span>Resources</span>
                </Link>
              </StyledListItem>
              <StyledListItem>
                <Link to={'/history'} onClick={() => handleToggle()}>
                  <MenuIcon className={`icon-clock icons`} />
                  <span>History</span>
                </Link>
              </StyledListItem>
              <StyledListItem>
                <Link to={'/airports'} onClick={() => handleToggle()}>
                  <MenuIcon className={`icon-plane icons`} />
                  <span>Airports</span>
                </Link>
              </StyledListItem>
              <StyledListItem>
                <Link to={'/members'} onClick={() => handleToggle()}>
                  <MenuIcon className={`icon-location-pin icons`} />
                  <span>Members</span>
                </Link>
              </StyledListItem>
              <StyledListItem>
                <Link to={'/messages'} onClick={() => handleToggle()}>
                  <MenuIcon className={`icon-envelope-letter icons`} />
                  <span>Messages</span>
                </Link>
              </StyledListItem>
              <StyledListItem>
                <Link to={'/users'} onClick={() => handleToggle()}>
                  <MenuIcon className={`icon-people icons`} />
                  <span>Users</span>
                </Link>
              </StyledListItem>

              <StyledListItem>
                <Link to={''} onClick={() => logout()} border="1px">
                  <MenuIcon className={`icon-key icons`} />
                  <span>Logout</span>
                </Link>
              </StyledListItem>
            </StyledList>
          </StyledListWrap>
          {/* )} */}
        </ListContainer>
      </StyledDrawer>
    </StyledNavigationPanel>
  )
}

export default Navigation
